import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const position = [-33.41954371650327, -70.60077189990679];

let markerIcon = new L.Icon({
  iconUrl: "/inicio/marker-icon.png",
  shadowUrl: "/inicio/marker-shadow.png"
});

export default function Map() {
  return (
    <MapContainer className="map" center={position} zoom={17} scrollWheelZoom={false}>
      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
      <Marker position={position} icon={markerIcon}>
        <Popup>
          Alliance Santiago - Chile
          <br/>
          Av. Hernando de Aguirre 162, Providencia
        </Popup>
      </Marker>
    </MapContainer>
  );
};
