import Map from '../components/Map/Map';
import React from 'react';
import Swal from 'sweetalert2'

/* const transporter = nodemailer.createTransport({
  host: 'mail.alliancebjj.cl',
  port: 465,
  secure: true,
  auth: {
    user: 'noreply@alliancebjj.cl',
    pass: '*g.QD89Pal*n',
  },
}); */

export default function Contacto() {
  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = document.getElementById("form-name").value;
    const email = document.getElementById("form-email").value;
    const message = document.getElementById("form-message").value;

    fetch('https://alliancebjj.cl/login/script/sendEmail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        message
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(result => {
      Swal.fire({
        title: "Correo Enviado!",
        text: "El correo electrónico ha sido enviado exitosamente!",
        icon: "success"
      });
      document.getElementById("form-name").value = "";
      document.getElementById("form-email").value = "";
      document.getElementById("form-message").value = "";
      console.log(result);
    })
    .catch(error => {
      Swal.fire({
        title: "Error!",
        text: "El correo electrónico no ha sido enviado",
        icon: "error"
      });
      document.getElementById("form-name").value = "";
      document.getElementById("form-email").value = "";
      document.getElementById("form-message").value = "";
      console.log(error);
    });
  };

  return (
    <>
      <h2 className="contacto-title">Información de Contacto</h2>
      <div className="contacto-container">
        <div className="contacto-flex">
          <article className="contacto-flex-1">
            <div className="mb-2">
              <p className="title">Correo electrónico</p>
              <p className="data"><a id="contacto-anchor" href="mailto:contacto@alliancebjj.cl">contacto@alliancebjj.cl</a></p>
            </div>
            <div className="mb-2">
              <p className="title">Teléfono</p>
              <p className="data"><a id="contacto-anchor" href="tel:+56948999358">+56 9 4899 9358</a></p>
            </div>
            <div className="mb-2">
              <p className="title">Ubicación</p>
              <p className="data">Av. Hernando de Aguirre 162, Providencia</p>
            </div>
          </article>
          <form className="contacto-flex-2" onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-2">
              <label htmlFor="form-name" className="form-label title">Nombre</label>
              <input type="text" className="form-control" id="form-name" placeholder="Nombre" required/>
            </div>
            <div className="mb-2">
              <label htmlFor="form-email" className="form-label title">Dirección de correo electrónico</label>
              <input type="email" className="form-control" id="form-email" placeholder="nombre@ejemplo.com" required/>
            </div>
            <div className="mb-3">
              <label htmlFor="form-message" className="form-label title">Mensaje</label>
              <textarea className="form-control" id="form-message" rows="3" placeholder="Escribe tu mensaje..." required></textarea>
            </div>
            <input id="form-button" className="btn btn-primary form-button" type="submit" value="Enviar mensaje"></input>
          </form>
        </div>
      </div>
      <Map></Map>
    </>
  );
};
