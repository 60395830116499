import React, { Component } from 'react';
import { Carousel } from "react-bootstrap";
class CarouselComp extends Component {
    render() {
        return (
            <div id='slider'>
                <Carousel>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/01-INICIANTES.jpeg"
                            alt="INICIANTES"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/02-PRINCIPIANTES.jpeg"
                            alt="PRINCIPIANTES"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/03-INTERMEDIO.jfif"
                            alt="INTERMEDIO"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/04-AVANZADO.jfif"
                            alt="AVANZADO"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/05-COMPETIDOR.jpeg"
                            alt="COMPETIDOR"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/06-DRILLS.jpeg"
                            alt="DRILLS"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/07-PREPARACION_FISICA.jfif"
                            alt="PREPARACION FISICA"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/08-MASTER.jfif"
                            alt="MASTER"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/09-JUDO.jfif"
                            alt="JUDO"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/10-INFANTIL.jfif"
                            alt="INFANTIL"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/11-JUVENIL.jfif"
                            alt="JUVENIL"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/12-CLASES_PARTICULARES.jfif"
                            alt="CLASES PARTICULARES"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="/inicio/carrusel/13-OPEN_MAT.jfif"
                            alt="OPEN MAT"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default CarouselComp;