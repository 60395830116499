import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { AiFillPhone } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { FaGlobeAmericas } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { BsInstagram } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { FaPatreon } from 'react-icons/fa';

const Footer = () => {
  return(
    <MDBFooter style={{ backgroundColor: 'rgba(10, 10, 10)', color: 'white' }} className='text-center text-lg-start text-muted'>
      <section style={{color: 'white'}}className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' id="social">
        <div className='me-5 d-none d-lg-block'>
          <span>Redes sociales:</span>
        </div>
        <div>
          <a href='https://www.instagram.com/alliancebjjsantiago/' className='me-4 text-reset'>
            <BsInstagram/>
          </a>
          <a href='https://www.youtube.com/channel/UCnjFFE0amnVCMMob7hJS0Qg/videos' className='me-4 text-reset'>
            <BsYoutube/>
          </a>
          <a href='https://www.patreon.com/AllianceSTGO?l=es' className='me-4 text-reset'>
            <FaPatreon/>
          </a>

        </div>
      </section>
      <section style={{color: 'white'}} id='footer-section'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <FaGlobeAmericas/> Alliance Chile
              </h6>
              <p>
                Somos una academia con más de 10 años de trayectoria, contamos con un respaldo internacional, siendo Alliance el N°1 en el mundo de acuerdo a los parámetros de la IBJJF
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Clases</h6>
              <p>
                Jiu Jitsu
              </p>
              <p>
                Grappling
              </p>
              <p>
                Judo
              </p>
              <p>
                Entrenamiento Funcional
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contacto</h6>
              <p>
                <FaMapMarkedAlt/> Av. Hernando de Aguirre 162, Providencia, Región Metropolitana.
              </p>
              <p>
                <IoIosMail/> contacto@alliancebjj.cl
              </p>
              <p>
                <AiFillPhone/> +56 9 4899 9358
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(10, 10, 10)' }}>
        <p>© 2022 Copyright: <a className='text-reset fw-bold' href='https://alliancebjj.cl/inicio/'>Alliance Santiago Chile</a></p>
      </div>
    </MDBFooter>
  );
};
export default Footer;