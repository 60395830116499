import React, { Component } from 'react';
import { Navbar,Container,Nav } from 'react-bootstrap';
import { FaShareAlt } from 'react-icons/fa';
class NavComp extends Component {
    render() {
        return (
            <div id='navbar'>
                <Navbar collapseOnSelect expand="lg" style = {{color:'#FFB200'}} /* bg="dark" variant="dark" */ fixed="top">
                    <Container>
                        <Navbar.Brand id="navbar-link" href="#inicio">Alliance</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link id="navbar-link" href="#planes">Planes</Nav.Link>
                            <Nav.Link id="navbar-link" href="#nosotros">Nosotros</Nav.Link>
                            <Nav.Link id="navbar-link" href="#contacto">Contacto</Nav.Link>
                            <Nav.Link id="navbar-link" href="#social"><FaShareAlt/></Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link id="navbar-link" href="https://alliancebjj.cl/login/index.php">Iniciar Sesión</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                </Container>
                </Navbar>
            </div>
        );
    }
}

export default NavComp;