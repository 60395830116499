import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import Alejandro from '../images/alejandro.jpg';
class CardComp extends Component {
    render() {
        return (
            <div>
                <Card style={{ width: '20rem' }}>
                    <Card.Img variant="top" src={Alejandro} />
                    <Card.Body>
                        <Card.Title>Alejandro Villaparedes Quiroz</Card.Title>
                        <Card.Text>
                        Gerente líder de equipo en Alliance Chile con la certificación de Alliance internacional.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default CardComp;