import React from 'react';

const PlanesEntrenamiento = () => {

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Planes de Entrenamiento</h2>
      <p style={{ textAlign: 'center', marginBottom: '40px' }}>
        Ofrecemos planes de entrenamiento especializados, diseñados para adaptarse a las necesidades de todos nuestros alumnos.
        Ya sea que seas principiante o avanzado, nuestras clases están estructuradas para ayudarte a alcanzar tus objetivos.
      </p>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: '20px' }}>
        <div style={{ cursor: 'pointer', width: '280px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '20px', textAlign: 'center' }}>
          <a href='#contacto' style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src="/inicio/planes/ALLIANCE.jpg" alt="ALLIANCE" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} className="card-image"/>
            <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Alliance</h3>
            <p style={{ fontSize: '1em', color: '#777' }}>
              Podrás acceder a todas las clases, incluyendo las sesiones de preparación física grupales. ¡Aprovecha al máximo cada entrenamiento!
            </p>
          </a>
        </div>

        <div style={{ cursor: 'pointer', width: '280px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '20px', textAlign: 'center' }}>
          <a href='#contacto' style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src="/inicio/planes/KIDS.jfif" alt="KIDS" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} className="card-image"/>
            <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Kids</h3>
            <p style={{ fontSize: '1em', color: '#777' }}>
              Plan exclusivo para niños entre 3 a 11 años, con profesores comprometidos con el apredizaje de cada estudiante, clases dinámicas y entretenidas!
            </p>
          </a>
        </div>

        <div style={{ cursor: 'pointer', width: '280px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '20px', textAlign: 'center' }}>
          <a href='#contacto' style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src="/inicio/planes/JUVENIL.jfif" alt="JUVENIL" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} className="card-image"/>
            <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Juvenil</h3>
            <p style={{ fontSize: '1em', color: '#777' }}>
              Plan exclusivo para adolescentes desde los 12 años hasta los 17, ¡No te quedes fuera!
            </p>
          </a>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <p>
            <strong>Plan Alliance:</strong><br />
            $95.000 mensual // $420.000 semestral // $700.000 anual
          </p>
          <p>
            <strong>Plan Kids y Juvenil:</strong><br />
            $65.000 mensual // $150.000 tres meses // $500.000 anual
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanesEntrenamiento;
